import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = ({ isArchivio }) => {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = 'https://www.scimexe.it/api/v1/paziente';
        if (isArchivio) {
          endpoint = 'https://www.scimexe.it/api/v1/paziente/archivio';
        }
        const response = await axios.get(endpoint, { withCredentials: true });
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    fetchData();
  }, [isArchivio]);

  // Funzione per formattare una data da timestamp Unix a una stringa leggibile
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  return (
    <div className="dashboard-container">
      <div className="d-flex justify-content-between mb-3">
        <h3>{isArchivio ? "Elenco pazienti archiviati": "Elenco pazienti"}</h3>
        {!isArchivio && <Link to="/crea-paziente" className="btn btn-success">Nuovo paziente +</Link>}
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Data di nascita</th>
              <th>Creato il</th>
              <th>Modificato il</th>
              <th>Azioni</th> 
              <th>Anomalie</th>
            </tr>
          </thead>
          <tbody>
            {patients.map(patient => (
              <tr key={patient.id}>
                <td>{patient.id}</td>
                <td>{patient.nome}</td>
                <td>{patient.cognome}</td>
                <td>{new Date(patient.dataDiNascita).toLocaleDateString()}</td>
                <td>{formatTimestamp(patient.createdAt)}</td>
                <td>{formatTimestamp(patient.modifiedAt)}</td>
                <td>
                  <Link to={`/paziente/${patient.id}`} className="btn btn-success">Dettaglio</Link>
                </td>
                <td>{patient.anomalia ? <span className="danger-icon">&#9888;</span> : null}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
