// Sidebar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Importa il file CSS

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <nav id="sidebar" className={`position-fixed bg-light vh-100 p-3 ${collapsed ? 'collapsed' : ''}`}>
      <div className="position-sticky">
        <button className="btn btn-link btn-sm position-absolute top-0 start-100 translate-middle" onClick={handleCollapseToggle}>
          <i className={`bi ${collapsed ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i>
        </button>
        <ul className={`nav flex-column ${collapsed ? 'd-none' : ''}`}>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Pazienti
            </Link>
          </li>
        </ul>
        <ul className={`nav flex-column ${collapsed ? 'd-none' : ''}`}>
          <li className="nav-item">
            <Link className="nav-link" to="/archivio">
              Pazienti archiviati
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
