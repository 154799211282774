import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const DettaglioPaziente = () => {
  const [patient, setPatient] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedSurname, setEditedSurname] = useState('');
  const [editedBirthDate, setEditedBirthDate] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchPatient(id);
    } else {
      setPatient({
        id: null,
        nome: '',
        cognome: '',
        dataDiNascita: '',
      });
    }
  }, [id]);

  const fetchPatient = async (patientId) => {
    try {
      const response = await axios.get(`https://www.scimexe.it/api/v1/paziente/${patientId}`, { withCredentials: true });
      if(response.data)
      {
        setPatient(response.data);
        setEditedName(response.data.nome);
        setEditedSurname(response.data.cognome);
        setEditedBirthDate(response.data.dataDiNascita?.substr(0, 10));
      }
      else{
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching patient:', error);
    }
  };

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const handleSurnameChange = (event) => {
    setEditedSurname(event.target.value);
  };

  const handleBirthDateChange = (event) => {
    setEditedBirthDate(event.target.value);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedPatient = { ...patient, nome: editedName, cognome: editedSurname, dataDiNascita: editedBirthDate };
      if (patient.id) {
        await axios.put(`https://www.scimexe.it/api/v1/paziente/${id}`, updatedPatient, { withCredentials: true });
      } else {
        const response = await axios.post(`https://www.scimexe.it/api/v1/paziente`, updatedPatient, { withCredentials: true });
        setPatient(response.data);
      }
      alert('Modifiche salvate con successo!');
      navigate('/');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Si è verificato un errore durante il salvataggio delle modifiche.');
    }
  };

  const handleArchivePatient = async () => {
    if (window.confirm(patient?.archiviato ? "Vuoi rimuovere questo paziente dall'archivio?" : 'Sei sicuro di voler archiviare questo paziente?')) {
      try {
        await axios.delete(`https://www.scimexe.it/api/v1/paziente/archivia/${id}`, { withCredentials: true });
        alert(patient?.archiviato ? 'Paziente de-archiviato con successo!' : 'Paziente archiviato con successo!');
        navigate('/');
      } catch (error) {
        console.error('Error archiving patient:', error);
        alert('Si è verificato un errore durante l\'archiviazione del paziente.');
      }
    }
  };

  const handleDeletePatient = async () => {
    if (window.confirm("Sei sicuro di voler eliminare questo paziente? \nL'azione è irreversibile.")) {
      try {
        await axios.delete(`https://www.scimexe.it/api/v1/paziente/elimina/${id}`, { withCredentials: true });
        alert('Paziente eliminato con successo!');
        navigate('/');
      } catch (error) {
        console.error('Error deleting patient:', error);
        alert('Si è verificato un errore durante l\'eliminazione del paziente.');
      }
    }
  };

  const handleViewCard = () => {
    // Logica per visualizzare la scheda del paziente
    alert('Visualizza scheda cliccato!');
  };

  return (
    <div className="container mt-5 position-relative">
      <div className="card">
        <div className="card-body">
          <h2 className="mb-4">{id ? `Dettaglio paziente "${patient?.nome} ${patient?.cognome}"` : 'Nuovo Paziente'}</h2>
          <div className="d-flex justify-content-end mb-3">
            {(id && patient && !patient.archiviato) && <button onClick={handleDeletePatient} className="btn btn-danger me-3">Elimina paziente</button>}
            {id && patient && <button onClick={handleArchivePatient} className="btn btn-warning me-3">{patient.archiviato ? "Rimuovi dall'archivio" : "Archivia paziente"}</button>}
            {patient && !patient.archiviato && <button onClick={handleSaveChanges} className="btn btn-primary">Salva modifiche</button>}
          </div>
          {patient && (
            <>
              <div className="row mb-3">
                <div className="col-md-6">
                  <input type="text" value={editedName} onChange={handleNameChange} className="form-control" disabled={patient.archiviato} placeholder="Nome" />
                </div>
                <div className="col-md-6">
                  <input type="text" value={editedSurname} onChange={handleSurnameChange} className="form-control" disabled={patient.archiviato} placeholder="Cognome" />
                </div>
              </div>
              <div className="mb-3">
                <input type="date" value={editedBirthDate} onChange={handleBirthDateChange} className="form-control" disabled={patient.archiviato} />
              </div>
              <div className="d-flex justify-content-end">
                <button onClick={handleViewCard} className="btn btn-success">Visualizza Scheda</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DettaglioPaziente;
