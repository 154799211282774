import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import './Auth.css';

const Auth = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [ripetiPassword, setRipetiPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isLogin) {
        const response = await axios.post('https://www.scimexe.it/api/v1/auth/signin', {
          email,
          password,
        }, { withCredentials: true });
        console.log('Login effettuato con successo:', response.data);
        
        onLogin();
        return <Navigate to="/dashboard" replace />;
      } else {
        if (password !== ripetiPassword) {
          setPasswordMatchError(true);
          setLoading(false);
          return;
        }

        const response = await axios.post('https://www.scimexe.it/api/v1/auth/signup', {
          email,
          password,
          firstName: nome,
          lastName: cognome,
        });
        console.log('Registrazione effettuata con successo:', response.data);
      }
    } catch (error) {
      console.error('Si è verificato un errore:', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleLogin = (e) => {
    e.preventDefault();
    setIsLogin(!isLogin);

    if (!isLogin) {
      setPassword('');
      setNome('');
      setCognome('');
      setRipetiPassword('');
    }
  };
  
  const handleForgotPassword = (e) => {
    e.preventDefault();
    console.log('Hai dimenticato la password?');
    // Implementa il recupero della password qui...
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title label-center">{isLogin ? 'Login' : 'Registrazione'}</h2>
              {loading && <i className="bi bi-arrow-clockwise spin-icon"></i>}
              <div className={`${passwordMatchError ? 'text-danger d-block ms-2' : 'd-none'}`}>
                <i className="bi bi-dot"></i> Le password non corrispondono.
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label label-left">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="example@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {!isLogin && (
                  <>
                    <div className="mb-3">
                      <label htmlFor="nome" className="form-label label-left">Nome:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nome"
                        placeholder="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="cognome" className="form-label label-left">Cognome:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cognome"
                        placeholder="Cognome"
                        value={cognome}
                        onChange={(e) => setCognome(e.target.value)}
                        required
                      />
                    </div>
                  </>
                )}
                <div className="mb-3">
                  <label htmlFor="password" className="form-label label-left">Password:</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete='on'
                      required
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                    </button>
                  </div>
                </div>
                {!isLogin && (
                  <div className="mb-3">
                    <label htmlFor="ripetiPassword" className="form-label label-left ">Ripeti Password:</label>
                    <div className="input-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        id="ripetiPassword"
                        placeholder="Ripeti Password"
                        value={ripetiPassword}
                        onChange={(e) => setRipetiPassword(e.target.value)}
                        required
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={togglePasswordVisibility}
                      >
                        <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                      </button>
                    </div>
                  </div>
                )}
                <div className="d-grid gap-2">
                  <div className="text-center">
                    <button type="submit" className="btn btn-outline-success btn-lg btn-block btn-third-width rounded-pill">{isLogin ? 'Accedi' : 'Registrati'}</button>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mt-3 text-start">
                    <button className="btn link-success" onClick={handleToggleLogin}>
                      {isLogin ? 'Non hai un account?' : 'Hai già un account?'}
                    </button>
                  </p>
                  {isLogin && (
                    <p className="mt-3 text-end">
                      <button className="btn link-success" onClick={handleForgotPassword}>
                        Hai dimenticato la password?
                      </button>
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
