import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Dashboard from './components/Dashboard/Dashboard';
import Auth from './components/Auth/Auth';
import NotFound from './components/NotFound/NotFound';
import axios from 'axios';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import DettaglioPaziente from './components/DettaglioPaziente/DettaglioPaziente';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLogout = useCallback(() => {
    axios.post('https://www.scimexe.it/api/v1/auth/logout', {}, { withCredentials: true })
      .then(() => {
        setLoggedIn(false);
      })
      .catch(error => {
        console.error('Errore durante il logout:', error);
      });
  }, []);

  const verifyToken = useCallback(async () => {
    try {
      const response = await axios.post('https://www.scimexe.it/api/v1/auth/verify-token', {}, { withCredentials: true });
      if (response.data === "Token valido") {
        setLoggedIn(true);
      } else {
        console.error('Token non valido:', response.data);
        setLoggedIn(false);
      }
    } catch (error) {
      console.error('Errore durante la verifica del token:', error);
      setLoggedIn(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  if (loading) {
    return <div>Caricamento...</div>;
  }

  return (
    <Router>
      <>
        <Navbar loggedIn={loggedIn} onLogout={handleLogout} />
        {loggedIn && <Sidebar />}
        <Routes>
          <Route path="/" element={loggedIn ? <Dashboard loggedIn={loggedIn} /> : <Auth onLogin={handleLogin} />} />
          <Route path="/archivio" element={loggedIn ? <Dashboard isArchivio /> : <Auth onLogin={handleLogin} />} />
          <Route path="/paziente/:id" element={loggedIn ? <DettaglioPaziente /> : <Auth onLogin={handleLogin} />} />
          <Route path="/crea-paziente" element={loggedIn ? <DettaglioPaziente /> : <Auth onLogin={handleLogin} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </Router>
  );
};

export default App;
